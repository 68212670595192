.form-control:disabled,
.form-control.disabled {
    background: #f8f9fa !important;
    color: #6a7178 !important;
}
.custom-control-inline {
	display: inline-flex;

	+ .custom-control-inline {
		margin-left:0;
	}
}